  <template>
  <div>
    <div class="editor-wrap">
      <div class="heading-primary">Timed Content</div>
      <div class="promo-expire">
        <div class="date-items">
          <label>Year</label>
          <select v-model="year" name="year">
            <option value="" selected disabled hidden>Choose here</option>
            <option value="2020">2020</option>
            <option value="2021">2021</option>
            <option value="2022">2022</option>
            <option value="2023">2023</option>
            <option value="2024">2024</option>
            <option value="2025">2025</option>
          </select>
        </div>
        <div class="date-items">
          <label>Month</label>
          <select v-model="month" name="month">
            <option value="" selected disabled hidden>Choose here</option>
            <option value="0">JAN</option>
            <option value="1">FEB</option>
            <option value="2">MAR</option>
            <option value="3">APR</option>
            <option value="4">MAY</option>
            <option value="5">JUN</option>
            <option value="6">JUL</option>
            <option value="7">AUG</option>
            <option value="8">SEP</option>
            <option value="9">OCT</option>
            <option value="10">NOV</option>
            <option value="11">DEC</option>
          </select>
        </div>
        <div class="date-items">
          <label>Day</label>
          <select v-model="day" name="day">
            <option value="" selected disabled hidden>Choose here</option>
            <option>1</option>
            <option>2</option>
            <option>3</option>
            <option>4</option>
            <option>5</option>
            <option>6</option>
            <option>7</option>
            <option>8</option>
            <option>9</option>
            <option>10</option>
            <option>11</option>
            <option>12</option>
            <option>13</option>
            <option>14</option>
            <option>15</option>
            <option>16</option>
            <option>17</option>
            <option>18</option>
            <option>19</option>
            <option>20</option>
            <option>21</option>
            <option>22</option>
            <option>23</option>
            <option>24</option>
            <option>25</option>
            <option>26</option>
            <option>27</option>
            <option>28</option>
            <option>29</option>
            <option>30</option>
            <option>31</option>
          </select>
        </div>
        <div class="date-items">
          <label>Hour</label>
          <select v-model="hour" name="hour">
            <option value="" selected disabled hidden>Choose here</option>
            <option>1</option>
            <option>2</option>
            <option>3</option>
            <option>4</option>
            <option>5</option>
            <option>6</option>
            <option>7</option>
            <option>8</option>
            <option>9</option>
            <option>10</option>
            <option>11</option>
            <option>12</option>
            <option>13</option>
            <option>14</option>
            <option>15</option>
            <option>16</option>
            <option>17</option>
            <option>18</option>
            <option>19</option>
            <option>20</option>
            <option>21</option>
            <option>22</option>
            <option>23</option>
          </select>
        </div>
        <div class="date-items">
          <label>Minute</label>
          <select v-model="min" name="min">
            <option value="" selected disabled hidden>Choose here</option>
            <option>0</option>
            <option>15</option>
            <option>30</option>
            <option>45</option>
          </select>
        </div>
      </div>
      <div class="promo-title">
        <label>Promo Band Title</label>
        <input type="text" name="PromoTitle" v-model="title">
      </div>
      <div class="promo-preview">
        <label>Promo Image Preview (600px * 338px)</label>
        <div v-if="promoimageData!=null">
          Progress: {{uploadPromoValue.toFixed()+"%"}}
          <progress id="promoprogress" :value="uploadPromoValue" max="100" ></progress>
        </div>
        <div v-if="promopic!=null" style="margin-top: 15px;">
          <img :src="promopic" class="sm-preview">
          <span @click="delPromoIMG" class="delPromoIMG">DEL</span>
        </div>
        <input  id="uploadpromo" type="file" @change="previewPromoImage" accept="image/*" ref="promofileupload" />
      </div>
      <div class="promo-video">
        <label>Promo Band Video (YouTube ID)</label>
        <input type="text" name="vid" v-model="vid">
      </div>
      <label>Promo Band Text</label>
      <div class="editor">
        <editor-menu-bar :editor="editor" v-slot="{ commands, isActive }">
          <div class="menubar">
            <button class="menubar__button" :class="{ 'is-active': isActive.bold() }" @click="commands.bold">
            <icon name="bold" />
          </button>
        <button class="menubar__button" :class="{ 'is-active': isActive.italic() }" @click="commands.italic">
          <icon name="italic" />
        </button>

        <button class="menubar__button" :class="{ 'is-active': isActive.strike() }"  @click="commands.strike">
          <icon name="strike" />
        </button>

        <button class="menubar__button" :class="{ 'is-active': isActive.underline() }" @click="commands.underline">
          <icon name="underline" />
        </button>

        <button class="menubar__button" :class="{ 'is-active': isActive.code() }" @click="commands.code">
          <icon name="code" />
        </button>

        <button class="menubar__button" :class="{ 'is-active': isActive.paragraph() }" @click="commands.paragraph">
          <icon name="paragraph" />
        </button>

        <button class="menubar__button" :class="{ 'is-active': isActive.heading({ level: 1 }) }" @click="commands.heading({ level: 1 })">
          H1
        </button>

        <button class="menubar__button" :class="{ 'is-active': isActive.heading({ level: 2 }) }" @click="commands.heading({ level: 2 })">
          H2
        </button>

        <button class="menubar__button" :class="{ 'is-active': isActive.heading({ level: 3 }) }" @click="commands.heading({ level: 3 })">
          H3
        </button>

        <button class="menubar__button" :class="{ 'is-active': isActive.bullet_list() }" @click="commands.bullet_list">
          <icon name="ul" />
        </button>

        <button class="menubar__button" :class="{ 'is-active': isActive.ordered_list() }" @click="commands.ordered_list">
          <icon name="ol" />
        </button>

        <button class="menubar__button" :class="{ 'is-active': isActive.blockquote() }" @click="commands.blockquote">
          <icon name="quote" />
        </button>

        <button class="menubar__button" :class="{ 'is-active': isActive.code_block() }" @click="commands.code_block">
          <icon name="code" />
        </button>

        <button class="menubar__button" @click="commands.horizontal_rule">
          <icon name="hr" />
        </button>

        <button class="menubar__button" @click="commands.undo">
          <icon name="undo" />
        </button>

        <button class="menubar__button" @click="commands.redo">
          <icon name="redo" />
        </button>
      </div>
    </editor-menu-bar>
    </div>
    <editor-content class="editor__content" :editor="editor" />
      <button @click="save" class="save__btn">Save Timed Content</button>
      <div v-html="this.myhtml"></div>
    </div>
  </div>
</template>

<script>
import firebase from 'firebase/app';
import 'firebase/storage';
import 'firebase/firestore';

import Icon from '@/components/Icons.vue'

import { Editor, EditorContent, EditorMenuBar } from 'tiptap'
import {
  Blockquote,
  CodeBlock,
  HardBreak,
  Heading,
  HorizontalRule,
  OrderedList,
  BulletList,
  ListItem,
  TodoItem,
  TodoList,
  Bold,
  Code,
  Italic,
  Link,
  Strike,
  Underline,
  History,
} from 'tiptap-extensions'

export default {
  metaInfo: {
    title: 'DIY Marketing Resources APP | Add Limited Content'
  },
  components: {
    EditorMenuBar,
    EditorContent,
    Icon
  },
  data() {
    return {
      keepInBounds: true,
      eventdate: '',
      eventspeaker: '',
      eventtitle: '',
      eventurl: '',
      rtitle: '',
      rpreview: '',
      rtype: '',
      rurl: '',
      vid: '',
      title: '',
      desc: '',
      year: '',
      month: '',
      day: '',
      hour: '',
      min: '',
      id: '',
      imageData: null,
      picture: null,
      uploadValue: 0,
      promopicture: null,
      promoimageData: null,
      uploadPromoValue: 0,
      promopic: null,
      editor: null,
      myhtml: '',
      user: null
    }
  },
  methods: {
    deleteIMG() {
      let image = firebase.storage().refFromURL(this.picture);
      image.delete();
      this.imageData=null
      this.$refs.fileupload.value = ''
    },
    deletePromoIMG() {
      let promoimage = firebase.storage().refFromURL(this.promopicture);
      promoimage.delete();
      this.promoimageData=null
      this.$refs.promofileupload.value = ''
    },
    delPromoIMG() {
      let promoimg = firebase.storage().refFromURL(this.promopic);
      promoimg.delete();
      this.promopic = ''
      this.promoimageData=null
    },
    previewImage(event) {
      this.uploadValue=0;
      this.picture=null;
      this.imageData = event.target.files[0];
      const storageRef=firebase.storage().ref(`${this.imageData.name}`).put(this.imageData);
      storageRef.on(`state_changed`,snapshot=>{
        this.uploadValue = (snapshot.bytesTransferred/snapshot.totalBytes)*100;
      }, error=>{console.log(error.message)},
      ()=>{this.uploadValue=100;
        storageRef.snapshot.ref.getDownloadURL().then((url)=>{
          this.picture =url;
        });
      }
      );
    },
    previewPromoImage(event) {
      this.uploadPromoValue=0;
      this.promopicture=null;
      this.promoimageData = event.target.files[0];
      const storagePromoRef=firebase.storage().ref(`${this.promoimageData.name}`).put(this.promoimageData);
      storagePromoRef.on(`state_changed`,snapshot=>{
        this.uploadPromoValue = (snapshot.bytesTransferred/snapshot.totalBytes)*100;
      }, error=>{console.log(error.message)},
      ()=>{this.uploadPromoValue=100;
        storagePromoRef.snapshot.ref.getDownloadURL().then((url)=>{
          this.promopicture =url;
          this.promopic=url;
        });
      }
      );
    },
    onUpload(){
      this.picture=null;
      const storageRef=firebase.storage().ref(`${this.imageData.name}`).put(this.imageData);
      storageRef.on(`state_changed`,snapshot=>{
        this.uploadValue = (snapshot.bytesTransferred/snapshot.totalBytes)*100;
      }, error=>{console.log(error.message)},
      ()=>{this.uploadValue=100;
        storageRef.snapshot.ref.getDownloadURL().then((url)=>{
          this.picture =url;
        });
      }
      );
    },
    save: function() {
      const uid = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
      var datum = new Date(this.year,this.month,this.day,this.hour,this.min,'0');
      var ts = datum.getTime()/1000;
      firebase
      .firestore()
      .collection("limited")
      .doc(uid)
      .set({
        title: this.title,
        vid: this.vid,
        img: this.promopic,
        desc: this.myhtml,
        year: this.year,
        month: this.month,
        day: this.day,
        hour: this.hour,
        min: this.min,
        expiry: ts,
        id: uid
      }).then((response) => {
        this.$router.push('/dashboard');
      }).catch((error) => {
        this.errors = error
      })
    }
  },
  mounted: function() {
    this.editor = new Editor({
      extensions: [
          new Blockquote(),
          new BulletList(),
          new CodeBlock(),
          new HardBreak(),
          new Heading({ levels: [1, 2, 3] }),
          new HorizontalRule(),
          new ListItem(),
          new OrderedList(),
          new TodoItem(),
          new TodoList(),
          new Link(),
          new Bold(),
          new Code(),
          new Italic(),
          new Strike(),
          new Underline(),
          new History(),
      ],
      content: '<h2>Hi there,</h2>',
      onUpdate: ({ getHTML }) => {
          this.myhtml = getHTML()
      }
    })
  }
}
</script>