<template>
  <div>
    <div v-if="this.created">
      <div class="login-wrap">
        <div class="heading-primary">Welcome!</div>
        <p>Your account has been created. Click the link below to access your content.</p>
        <router-link to="/home" class="btn">
          Show me the goodies
        </router-link>
      </div>
    </div>
    <div v-if="!this.created">
      <div class="setup-instructions">
        <div class="heading-tertiary">Create your login to Monthly Marketing Pro:</div>
        <ol>
          <li>Enter your email and click "Next"</li>
          <li>Create a password</li>
          <li>Click the “Setup” button</li>
          <li>You’re in!</li>
        </ol>
      </div>
      <div class="login-wrap" v-if="!this.showpw" style="margin-top: 0;">
        <form @submit.prevent="userCheck">
          <div class="email">
            <input type="email" v-model="setupemail" placeholder="email">
          </div>
          <button type="submit">Next</button>
        </form>
        <div class="error" v-if="error">{{this.error}}</div>
      </div>
      <div class="login-wrap" v-if="this.showpw" style="margin-top: 0;">
        <form @submit.prevent="userSetup">
          <div class="email">
            <input type="email" v-model="setupemail" placeholder="email">
          </div>
          <div class="password" v-if="this.showpw">
            <input type="password" v-model="setuppassword" placeholder="password" autocomplete="new-password">
          </div>
          <div class="password" v-if="this.showpw">
            <input type="password" v-model="verifypassword" placeholder="re-enter password" autocomplete="new-password">
          </div>
          <button type="submit">Setup</button>
        </form>
        <div class="error" v-if="error">{{this.error}}</div>
      </div>
      <div class="login-wrap">
      <p>Having trouble creating an account?<br>Email <a href="mailto:marketingplus@roadsidemultimedia.com">marketingplus@roadsidemultimedia.com</a></p>
      </div>
    </div>
  </div>
</template>

<script>
import firebase from 'firebase/app';
import 'firebase/auth';

export default {
  metaInfo: {
    title: 'DIY Marketing Resources APP | Account Setup'
  },
  data() {
    return {
      setupemail: '',
      setuppassword: '',
      verifypassword: '',
      error: '',
      user: null,
      showpw: '',
      created: ''
    }
  },
  created() {
    this.setupemail = this.$route.query.email;
  },
  methods: {
    userCheck() {
      const dbRef = firebase.database().ref("users")
      dbRef.orderByChild('email').equalTo(this.setupemail).once("value").then(snap => {
        console.log(snap.val());
        if(snap.val() == null) {
          console.log("false");
          this.error = "Sorry, no user associated with that email."
        } else {
          this.showpw = 'true';
          console.log('true');
          this.error = ''
        }
      }).catch(error => {
        console.log("error".error);
      })
    },
    userSetup() {
      if(this.setuppassword == this.verifypassword) {
        const dbRef = firebase.database().ref("users")
        dbRef.orderByChild('email').equalTo(this.setupemail).once("value").then(snap => {
          if(snap.val() == null) {
            this.error = "Sorry, no user associated with that email."
          } else {
            this.showpw = 'true';
            this.error = ''
            firebase
              .auth()
              .createUserWithEmailAndPassword(this.setupemail, this.setuppassword)
              .then(data => {
                this.created = true
              })
              .catch(err => {
                this.error = err.message;
              });

            var uKey = Object.keys(snap.val())[0];
            console.log(Object.keys(snap.val())[0]);
            dbRef.child(uKey).update({
              created: 'true'
            });
          }
        }).catch(error => {
          console.log("error".error);
        })
      } else {
        this.error = "Password do not match"
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.setup-instructions {
  text-align: center;
  display: block;
  margin: 80px auto 0;
  max-width: 400px;
  ol {
    text-align: left;
  }
}
</style>