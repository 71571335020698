<template>
  <div>
    <div class="sidebar_wrap show">
      <div class="b-sidebar">
        <nav>
          <button @click="goto('timed_content')" class="nav-link"><span>Time Content</span></button>
          <button @click="goto('upcoming_events')" class="nav-link"><span>Upcoming Events</span></button>
          <button @click="goto('diy_resources')" class="nav-link"><span>DIY Resources</span></button>
        </nav>
      </div>
    </div>
    <div class="editor-wrap">
      <div class="heading-primary" id="timed_content">Timed Content <router-link to="/add" class="btn">ADD</router-link></div>
      <div class="responsive-admin-table">
        <div class="rct_table"  v-if="this.$store.getters.setLimited && this.$store.getters.setLimited.length > 0">
          <div class="rct__row" v-for="limit in this.$store.getters.setLimited" :key="limit.id">
            <div class="rct__cell rct__date" data-title="Preview Image">
              <img :src="[limit.img]" class="sm-preview">
            </div>
            <div class="rct__cell rct__speaker" data-title="Title">
              {{limit.title}}
            </div>
            <div class="rct__cell rct__topic" data-title="Video ID">
              {{limit.vid}}
            </div>
            <div class="rct__cell rct__link" data-title="Expiry">
              {{limit.month}} | {{limit.day}} | {{limit.year}} - {{limit.hour}}:{{limit.min}}
              <br/> {{limit.expiry}}
            </div>
            <div class="rct__cell rct__tools" data-title="Options">
              <div class="delete__btn" @click="deleteLimited(limit.id)">DEL</div>
              <router-link :to="{ path: 'edit', query: { id: limit.id } }" class="edit__btn" >EDIT</router-link>
            </div>
          </div>
        </div>
      </div>

      <div class="heading-primary" id="upcoming_events" style="margin-top: 80px;">Upcoming Events</div>
      <div class="responsive-admin-table">
        <div class="rct_table"  v-if="this.$store.getters.setItems && this.$store.getters.setItems.length > 0">
          <div class="rct__row" v-for="item in this.$store.getters.setItems" :key="item.id">
            <div class="rct__cell rct__date" data-title="Date">
              {{item.date}}
            </div>
            <div class="rct__cell rct__speaker" data-title="Speaker(s)">
              {{item.speaker}}
            </div>
            <div class="rct__cell rct__topic" data-title="Topic">
              {{item.title}}
            </div>
            <div class="rct__cell rct__link" data-title="Register">
              <a href="#" target="blank">Click to register</a>
            </div>
            <div class="rct__cell rct__tools" data-title="Options">
              <div class="delete__btn" @click="deleteItem(item.id)">DEL</div>
            </div>
          </div>
        </div>
        <div class="rct_table">
          <div class="rct__row">
            <div class="rct__cell rct__date" data-title="Date">
              <input type="text" v-model="eventdate" placeholder="mm/dd/yyyy">
            </div>
            <div class="rct__cell rct__speaker" data-title="Speaker(s)">
              <input type="text" v-model="eventspeaker" placeholder="Speaker">
            </div>
            <div class="rct__cell rct__topic" data-title="Topic">
              <input type="text" v-model="eventtitle" placeholder="Topic">
            </div>
            <div class="rct__cell rct__link" data-title="Register">
              <input type="text" v-model="eventurl" placeholder="Register URL">
            </div>
            <div class="rct__cell rct__tools">
              <button @click="addevent" class="add__btn">Add</button>
            </div>
          </div> 
        </div>
      </div>
      <div class="heading-primary" id="diy_resources" style="margin-top: 80px;">DIY Resources  <router-link to="/add-painpoint" class="btn">ADD</router-link></div>
      <div class="responsive-admin-table">
        <div class="rct_table"  v-if="this.$store.getters.getPainpoints && this.$store.getters.getPainpoints.length > 0">
          <div class="rct__row" v-for="point in this.$store.getters.getPainpoints" :key="point.id">
            <div class="rct__cell rct__order" data-title="Order">
              {{point.order}}
            </div>
            <div class="rct__cell rct__date" data-title="Slug">
              {{point.slug}}
            </div>
            <div class="rct__cell rct__speaker" data-title="Title">
              {{point.title}}
            </div>
            <div class="rct__cell rct__link" data-title="URL">
              {{point.id}}
            </div>
            <div class="rct__cell rct__tools" data-title="Options">
              <div class="delete__btn" @click="deletePoint(point.id)">DEL</div>
              <router-link :to="{ path: 'edit-painpoint', query: { id: point.id } }" class="edit__btn" >EDIT</router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/storage';

export default {
  metaInfo: {
    title: 'DIY Marketing Resources APP | Dashboard'
  },
  beforeCreate() {
    this.$store.dispatch('setItems')
    this.$store.dispatch('setResources')
    this.$store.dispatch('setLimited')
    this.$store.dispatch('setContent')
    this.$store.dispatch('getPainpoints')
  },
  data() {
    return {
      eventdate: '',
      eventspeaker: '',
      eventtitle: '',
      eventurl: '',
      rid: '',
      redit: false,
      rorder: '',
      rtitle: '',
      rpreview: '',
      rtype: '',
      rurl: '',
      vid: '',
      title: '',
      desc: '',
      year: '',
      month: '',
      day: '',
      hour: '',
      min: '',
      id: '',
      imageData: null,
      picture: null,
      uploadValue: 0,
      promopicture: null,
      promoimageData: null,
      uploadPromoValue: 0,
      promopic: null,
      user: null
    }
  },
  methods: {
    percentCreated: function(created,total) {
      var perC = (parseFloat(created) / parseFloat(total)).toFixed(2);
      return perC;
    },
    percentRemaining: function(created,total) {
      var perR = (100-(parseFloat(created) / parseFloat(total))).toFixed(2);
      return perR;
    },
    checkUser: function(useremail) {
      if(useremail !== null) {
        
      }
    },
    deleteIMG: function() {
      let image = firebase.storage().refFromURL(this.picture);
      image.delete();
      this.imageData=null
      this.$refs.fileupload.value = ''
    },
    deletePromoIMG: function() {
      let promoimage = firebase.storage().refFromURL(this.promopicture);
      promoimage.delete();
      this.promoimageData=null
      this.$refs.promofileupload.value = ''
    },
    delPromoIMG: function() {
      let promoimg = firebase.storage().refFromURL(this.promopic);
      promoimg.delete();
      this.promopic = ''
      this.promoimageData=null
    },
    previewImage: function(event) {
      this.uploadValue=0;
      this.picture=null;
      this.imageData = event.target.files[0];
      const storageRef=firebase.storage().ref(`${this.imageData.name}`).put(this.imageData);
      storageRef.on(`state_changed`,snapshot=>{
        this.uploadValue = (snapshot.bytesTransferred/snapshot.totalBytes)*100;
      }, error=>{console.log(error.message)},
      ()=>{this.uploadValue=100;
        storageRef.snapshot.ref.getDownloadURL().then((url)=>{
          this.picture =url;
        });
      }
      );
    },
    previewPromoImage: function(event) {
      this.uploadPromoValue=0;
      this.promopicture=null;
      this.promoimageData = event.target.files[0];
      const storagePromoRef=firebase.storage().ref(`${this.promoimageData.name}`).put(this.promoimageData);
      storagePromoRef.on(`state_changed`,snapshot=>{
        this.uploadPromoValue = (snapshot.bytesTransferred/snapshot.totalBytes)*100;
      }, error=>{console.log(error.message)},
      ()=>{this.uploadPromoValue=100;
        storagePromoRef.snapshot.ref.getDownloadURL().then((url)=>{
          this.promopicture =url;
          this.promopic=url;
        });
      }
      );
    },
    onUpload: function(){
      this.picture=null;
      const storageRef=firebase.storage().ref(`${this.imageData.name}`).put(this.imageData);
      storageRef.on(`state_changed`,snapshot=>{
        this.uploadValue = (snapshot.bytesTransferred/snapshot.totalBytes)*100;
      }, error=>{console.log(error.message)},
      ()=>{this.uploadValue=100;
        storageRef.snapshot.ref.getDownloadURL().then((url)=>{
          this.picture =url;
        });
      }
      );
    },
    addevent: function() {
      const uid = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
      firebase
      .firestore()
      .collection("events")
      .doc(uid)
      .set({
        id: uid,
        date: this.eventdate,
        title: this.eventtitle,
        speaker: this.eventspeaker,
        url: this.eventurl
      }).then((response) => {
        let myToast = this.$toasted.show("Event Added",{
          position: "top-center"
        });
        myToast.goAway(1000);
        this.eventdate = '',
        this.eventtitle = '',
        this.eventspeaker = '',
        this.eventurl = ''
      }).catch((error) => {
        this.errors = error
      })
    },
    addresource: function() {
      const uid = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
      firebase
      .firestore()
      .collection("resources")
      .doc(uid)
      .set({
        id: uid,
        order: this.rorder,
        type: this.rtype,
        title: this.rtitle,
        preview: this.picture,
        url: this.rurl
      }).then((response) => {
        let myToast = this.$toasted.show("Resource Added",{
          position: "top-center"
        });
        myToast.goAway(1000);
        this.$refs.fileupload.value = ''
        this.rtype = ''
        this.rtitle = ''
        this.rurl = ''
        this.rorder = ''
        this.imageData=null
      }).catch((error) => {
        this.errors = error
      })
    },
    deleteItem: function (id) {
      let myToast = this.$toasted.show("Delete item?",{
        action : [
        {
          text : 'Cancel',
          onClick : (e, toastObject) => {
              toastObject.goAway(0);
          }
        },
        {
          text : 'YES',
          onClick : (e, toastObject) => {
            toastObject.goAway(0);
            if (id) {
              firebase
              .firestore()
              .collection("events")
              .doc(id).delete().then(function() {
              }).catch(function(e) {
                this.errors = e
              })
            } else {
              this.erros = 'no ID'
            }
          }
        }
        ],
        position: "top-center"
      });
      
    },
    editPoint: function (id) {
      console.log(id);
      this.redit = true;
      var self = this

      if (id) {
        firebase
        .firestore()
        .collection("painpoints")
        .doc(id)
        .get().then(snapshot => {
        if (!snapshot.exists) return;
          let data = snapshot.data()
          this.rtitle = data.title
          this.rorder = data.order
          this.rtype = data.type
          this.rurl = data.url
          this.rid = data.id
          this.picture = data.preview
          this.imageData = true
        }).then((response) => {
          
        }).catch((error) => {
          this.errors = error
        })
      }
    },
    deletePoint: function (id) {
      var self = this
      let myToast = this.$toasted.show("Delete item?",{
        action : [
        {
          text : 'Cancel',
          onClick : (e, toastObject) => {
              toastObject.goAway(0);
          }
        },
        {
          text : 'YES',
          onClick : (e, toastObject) => {
            toastObject.goAway(0);
            if (id) {
              firebase
              .firestore()
              .collection("painpoints")
              .doc(id).delete().then(function() {

              }).catch(function(e) {
                self.errors = e
                console.log(e)
              });
            } else {
              self.errors = 'no ID'
            }
          }
        }
        ],
        position: "top-center"
      });
      
    },
    deleteLimited: function (id) {
      let myToast = this.$toasted.show("Delete item?",{
        action : [
        {
          text : 'Cancel',
          onClick : (e, toastObject) => {
              toastObject.goAway(0);
          }
        },
        {
          text : 'YES',
          onClick : (e, toastObject) => {
            toastObject.goAway(0);
            if (id) {
              firebase
              .firestore()
              .collection("limited")
              .doc(id).delete().then(function() {

              }).catch(function(e) {
                this.errors = e
              });
            } else {
              this.erros = 'no ID'
            }
          }
        }
        ],
        position: "top-center"
      });
      
    }
  },
  mounted: function() {
  }
}
</script>