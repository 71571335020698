<template>
  <div>
    <div class="section band-hero">
      <div class="row">
        <div class="col span_1">
          <h1 class="heading-hero-alt">Weekly Coach</h1>
        </div>
      </div>
    </div>
    <div class="section">
      <div class="row">
        <div v-html="campaignHTML"></div>       
      </div>
    </div>
  </div>
</template>

<script>
import firebase from 'firebase/app'
import 'firebase/auth';


export default {
  metaInfo: {
    title: 'DIY Marketing Resources APP | Weekly Coach'
  },
  data: function() {
    return {
      campaignHTML : ''
    }
  },
  methods: {
    async getMailChimp() {
      fetch(`/.netlify/functions/mailchimp`)
      .then( response => response.json())
      .then( (data) => {
        console.log(data);
        console.log("campaign id: " + data['campaigns'][0]['id']);
        let campaignID = data['campaigns'][0]['id'];

        return fetch(`/.netlify/functions/mailchimp-html?bananaID=${campaignID}`)
      })
      .then( response => response.json())
      .then( (data) => {
         this.campaignHTML = data['html']
      })
    }
  },
  created() {
    this.getMailChimp()
  }
}
</script>
<style>
  .mc-iframe {
    width: 100%;
    height: 4700px;
    border: 0;
  }
</style>