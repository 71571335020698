  <template>
  <div>
    <div class="section">
      <div class="row">
        <div class="col span_1">
          <h1 class="heading-hero-alt" style="margin: 30px 0;">{{title}}</h1>
        </div>
      </div>
      <div class="row">
        <template v-for="(item,idx) in pagecontent">
          <div v-if="item.type == 'title'" class="diy-title" :key="idx">{{item.text}}</div>
          <div v-if="item.type == 'iconcard'" class="diy-iconcard" :class="[item.cols === '1' ? '' : 'col-'+ item.cols]" :key="idx">
            <div v-for="(card,idx) in item.items" :key="idx" class="diy-iconitem-wrap" :class="[card.link ? 'link' : '']">
              <a :href="[card.link]" target="_blank" v-if="card.link" class="diy-iconitem">
                <i :class="card.icon"></i>
                <div v-html="card.text"></div>
              </a>
              <div v-else class="diy-iconitem">
                <i :class="card.icon"></i>
                <div v-html="card.text"></div>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import firebase from 'firebase/app';
import 'firebase/storage';
import 'firebase/firestore';

export default {
  metaInfo: {
    title: 'DIY Marketing Resources APP | Painpoint'
  },
  components: {
  },
  data() {
    return {
      keepInBounds: true,
      title: '',
      slug: this.$route.params.slug,
      icon: '',
      id: '',
      order: '',
      pagecontent: []
    }
  },
  methods: {
    loadData: function() {
      let painpoint = []
      let currentslug = this.$route.params.slug;
      firebase
      .firestore()
      .collection("painpoints")
      .where('slug', '==', currentslug)
      .onSnapshot((snapshot) => {
        painpoint = []
        snapshot.forEach((doc) => {
          painpoint.push({id: doc.id, slug: doc.data().slug, icon: doc.data().icon, title: doc.data().title, order: doc.data().order, content: JSON.parse(doc.data().content)})
        })
        this.title = painpoint[0].title;
        this.pagecontent = painpoint[0].content;
      })
      
    },
    loadTop: function() {
      var top = 0;
      window.scroll({ top: top, behavior: 'smooth' });
    }
  },
  mounted() {
    this.loadData();
    this.loadTop();
  }
}
</script>