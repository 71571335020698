<template>
    <div class="login-wrap">
      <div class="setup-instructions">
        <div class="heading-tertiary">Welcome to the Marketing Resources Toolkit</div>
        <p>Log in to access this month’s education and resources!</p>
      </div>
      <div class="forget-wrap">
        <router-link to="/forgot">forgot password?</router-link>
      </div>
      <form @submit.prevent="userLogin">
        <div class="email">
          <input type="email" v-model="email" placeholder="email">
        </div>
        <div class="password">
          <input type="password" v-model="password" placeholder="password">
        </div>
        <button type="submit">Login</button>
      </form>
      <div class="error" v-if="error">{{this.error}}</div>
    </div>
</template>

<script>
import firebase from 'firebase/app';
import 'firebase/auth';

export default {
  metaInfo: {
    title: 'DIY Marketing Resources APP | Login'
  },
  data() {
    return {
      email: '',
      password: '',
      error: '',
      err: '',
      user: null
    }
  },
  created() {
    this.email = this.$route.query.email;
  },
  mounted() {
    this.checkuser()
  },
  methods: {
    userLogin() {
      firebase
        .auth()
        .signInWithEmailAndPassword(this.email, this.password)
        .then(() => {
            this.$router.replace('/home').catch(err => {})
        })
        .catch((error) => {
          alert(error.message);
        });
    },
    checkuser: function() { 
      if (firebase.auth().currentUser) {
        console.log('not logged in')
      } else {
        console.log('logged in')
        this.$router.replace('/home').catch(err => {})
      }
    }
  }
}
</script>